/* eslint-disable max-classes-per-file */
/* eslint-disable no-undef */
/* eslint-disable no-console */
import { version } from '../../package.json';

class NIMMSTALoader {
  static websocketPort = 64693;
  static onReadyListeners = [];
  static onErrorListeners = [];

  static promiseResolve;
  static promiseReject;

  static startedLoading = false;
  static isDocReady = false;

  /**
   * Defines whether to prefer websocket over accessibility service.
   * Accessibility service is only supported on android. Just set to false
   * if you know what you're doing.
   */
  static prefersWebsocketConnection = true;

  static version = version.slice(0, -2);
  static initializedByLoader = true;
  static communicationRetry = 0;

  /**
   * Send timeout in ms.
   */
  static requestSendTimeout = 1000;

  /**
   * Defines how often it tries to resent a request before it consideres it as failed completly.
   */
  static maxCommunicationRetries = 3;

  /**
   * If set to true, the connection to the NIMMSTA websocket is preserved, although focus
   * of the current window was lost. If false, it will disconnect on blur and reconnect onfocus.
   */
  static keepAlwaysConnected = false;

  static isLoadingJs = false;

  static onReady(fn) {
    this.onReadyListeners.push(fn);

    if (this.isDocReady && !this.startedLoading) {
      this.initialize();
    }
  }

  static docReady() {
    this.isDocReady = true;

    if (this.onReadyListeners.length > 0) {
      this.initialize();
    }
  }

  static onError(fn) {
    this.onErrorListeners.push(fn);
  }

  /**
   * Initializes NIMMSTA Web Library
   */
  static initialize() {
    // Prevent calling initialize multiple times
    if (this.startedLoading) {
      return;
    }
    this.startedLoading = true;
    console.log('Initializing loader');
    new Promise((resolve, reject) => {
      this.promiseResolve = resolve;
      this.promiseReject = reject;
      this.loadJavascript();
    }).then(() => {
      console.log('Initing bundle');

      // move listeners and configuration to new NIMMSTA object just loaded
      this.onReadyListeners.forEach((fn) => {
        NIMMSTA.onReady(fn);
      });
      this.onErrorListeners.forEach((fn) => {
        NIMMSTA.onError(fn);
      });
      NIMMSTA.websocketPort = this.websocketPort;
      NIMMSTA.initializedByLoader = this.initializedByLoader;
      NIMMSTA.prefersWebsocketConnection = this.prefersWebsocketConnection;
      NIMMSTA.keepAlwaysConnected = this.keepAlwaysConnected;
      NIMMSTA.requestSendTimeout = this.requestSendTimeout;
      NIMMSTA.maxCommunicationRetries = this.maxCommunicationRetries;

      this.communicationRetry = 0;

      console.log('Starting ConnectionManager');
      // eslint-disable-next-line no-new
      new NimmstaConnectionManager();
    }).catch((error) => {
      console.log(error);
      this.onErrorListeners.forEach((fn) => {
        fn(error);
      });
    });
  }

  /**
   * Tries to receive the javascript from the websocket
   * If it fails accessibility service gets tried
   */
  static loadJavascript() {
    console.log(`Loader JavaScript: Try: ${this.communicationRetry}`);

    this.isLoadingJs = true;

    this.socket = new WebSocket(`ws://localhost:${this.websocketPort}/`);

    this.socket.onopen = () => {
      this.socket.send(JSON.stringify({ type: 'JS' }));
    };

    this.socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.type === 'JS') {
        // If we receive a message, the socket has not been closed because of a problem
        // If we received the correct message, we can add the JS to the document
        // and execute it
        this.socket.onclose = null;
        this.socket.close();

        if (response.js !== null) {
          this.addJavascript(response.js);
        } else {
          console.error('Error on receiving JS from websocket:', response);
          console.log('Trying AccessibilityService');
          this.tryAccessibilityServiceOrRetryWebsocket();
        }
      }
    };

    this.socket.onclose = () => {
      console.log('Socket not available. Trying AccessibilityService');
      this.tryAccessibilityServiceOrRetryWebsocket();
    };
  }

  /**
   * Tries to receive the javascript from the accessibility service.
   * If a message is not detected in the input field within the timeout,
   * the websocket is retried.
   * We retry until the max retry value is reached, after which an error
   * is thrown.
   */
  static tryAccessibilityServiceOrRetryWebsocket() {
    const nimmstaInputElement = this.addNimmstaElement('NIMMSTA-INPUT-ELEMENT');
    const nimmstaOutputElement = this.addNimmstaElement('NIMMSTA-OUTPUT-ELEMENT');

    const cleanUp = () => {
      nimmstaInputElement.remove();
      nimmstaOutputElement.remove();
    };

    const interval = setInterval(() => {
      this.inputInField(nimmstaOutputElement);
    }, 100);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      console.error('AccessibilityService not available; NIMMSTA initialization failed!');
      this.communicationRetry++;
      cleanUp();
      if (this.communicationRetry >= NIMMSTA.maxCommunicationRetries) {
        this.isLoadingJs = false;
        this.promiseReject('Could not connect to app in time. Check if app is running and call NIMMSTA.tryConnect() again.');
      } else {
        // retry
        this.loadJavascript();
      }
    }, this.requestSendTimeout);

    nimmstaInputElement.onchange = () => {
      clearTimeout(timeout);
      clearInterval(interval);
      cleanUp();
      const response = JSON.parse(nimmstaInputElement.value);
      if (response.js !== null) {
        this.addJavascript(response.js);
      } else {
        console.error('Error on receiving JS from websocket:', response);
        this.promiseReject('Could not connect to app as app responded wrongly. Try upgrading NIMMSTA App.');
      }
    };
  }

  /**
   * Inputs the loading string into the output field
   */
  static inputInField(field) {
    // eslint-disable-next-line no-param-reassign
    field.value = '';
    const timeout = setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      field.value = JSON.stringify({ type: 'JS' });
    }, 50);
  }
  /**
   * Creates and add a hidden input field to the DOM of the webpage
   * @param id id of the input element to add
   */
  static addNimmstaElement(id) {
    const element = document.createElement('input');
    element.setAttribute('id', id);
    element.setAttribute('disabled', 'true');
    element.setAttribute('style', 'position: absolute; opacity: 0; height: 0px; width: 20px; pointer-events: none; user-select: none;');
    document.body.appendChild(element);
    return element;
  }

  /**
   * Adds the js to the head of the DOM
   */
  static addJavascript(js) {
    console.log('Adding JavaScript');
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.innerHTML = js;
    document.head.appendChild(script);
    this.promiseResolve();
  }

  /**
   * Tries to connect.
   */
  static tryConnect() {
    if (!this.isLoadingJs) {
      this.communicationRetry = 0;
      this.loadJavascript();
    }
  }
}

/**
 * Class for custom layouts
 * Can be extended from to define own layout classes
 * On how to extend this take a look at "./SuccessLayout.ts"
 */
class NimmstaLayout {
  layout;
  parameters = {};

  constructor(xml, parameters = {}) {
    this.layout = xml;
    this.parameters = parameters;
  }
}

if (window.NimmstaLayout == null) {
  window.NimmstaLayout = NimmstaLayout;
}

if (window.NIMMSTA != null) {
  console.error('NIMMSTA is already defined. Please import loader as first JavaScript.');
} else {
  // needed to put it on window after converting to es5
  window.NIMMSTA = NIMMSTALoader;
}

if (document.readyState === 'complete') {
  NIMMSTALoader.docReady();
} else {
  window.addEventListener('load', (_) => {
    NIMMSTALoader.docReady();
  }, false);
}

window.addEventListener('focus', (_) => {
  NIMMSTA.tryConnect();
}, false);
